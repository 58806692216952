import { Image } from '@chakra-ui/image';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/layout';
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import { ContactUsBannerWithForm, Hero, SubHero, MainServiceBanner } from '../../../components/shared';
import { useHandymanWorkService } from '../../../GraphQL/Queries';

const HandymanWork = ({ location }) => {
  /**
   * @TODO: Fix sizing of images to set h & width not %. Add blocks to mobile matching images
   */

  const data = useHandymanWorkService();

  const {
    acf_text_block_title,
    acf_image_text_block,
    acf_service_info,
    acf_services_block,
    acf_quote,
  } = data.residentialServiceBy;

  return (
    <Layout location={location}>
      <SEO title="Handyman work" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
        mobilePosition="80% 50%"
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />
      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />
      <Container
        pl={{ base: '2rem', md: 'unset' }}
        mt={{ base: '3rem', md: '9rem' }}
        mx="auto"
        position="relative"
        overflow="hidden"
        pb={{ base: '3rem', md: 'unset' }}
      >
        <Flex
          justifyContent="space-between"
          alignItems={{ base: 'flex-end', md: 'center' }}
          pb={{ base: '3rem', md: 'unset' }}
        >
          <Box w={{ base: '53%', md: '45%' }}>
            <Heading fontSize={{ base: '35px', md: '35px' }} color="primary" pb={{ base: '1rem', md: 'unset' }}>
              {acf_image_text_block.blockList[1].title}
            </Heading>
            <Text fontSize={{ base: '16px', md: '18px' }} mt={{ md: '1rem' }}>
              {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
            </Text>
          </Box>
          <Image
            display={{ base: 'block', md: 'none' }}
            src={acf_image_text_block.blockList[1].image.sourceUrl}
            position="absolute"
            left="55%"
            top="0"
            mt="-2.7rem"
            h="500px"
          />
          <Image display={{ base: 'none', md: 'block' }} src="/images/wallhole.png" h="400px" />
        </Flex>
      </Container>
      {/* Mobile Version - START - */}
      <Box display={{ base: 'block', lg: 'none' }} mb="5rem" mt="-2rem">
        {acf_services_block.servicesList.map((service) => (
          <Box mt="2rem" pl="2rem">
            <Image w="100%" h="216px" objectFit="cover" mt="1rem" src={service.mainImage.sourceUrl} />
            <Heading textStyle="servicePageHeading" mt="2rem" color="primary">
              {service.serviceTitle}
            </Heading>
            <Text mt="1rem" pr="2rem" textStyle="servicePageText">
              {service.serviceDescription}
            </Text>
          </Box>
        ))}
      </Box>
      {/* Mobile Version - END - */}

      {/* Desktop Version - START - */}
      <Box overflow="hidden">
        <Box display={{ base: 'none', lg: 'block' }} pos="relative" w="1750px" minH="690px" mx="auto" overflow="auto">
          <Box w="868px">
            <Image w="868px" h="464px" objectFit="cover" src={acf_services_block.servicesList[0].mainImage.sourceUrl} />
            <Box w="400px" ml="auto" py="2rem" mr="13rem">
              <Heading py="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
                {acf_services_block.servicesList[0].serviceTitle}
              </Heading>
              <Text fontSize="18px">{acf_services_block.servicesList[0].serviceDescription}</Text>
            </Box>
          </Box>

          <Box pos="absolute" right="0" top="0" w="732px">
            <Image w="732px" h="351px" objectFit="cover" src={acf_services_block.servicesList[2].mainImage.sourceUrl} />
            <Box width="300px" ml="11rem" pt="2rem">
              <Heading mt="1rem" pb="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
                {acf_services_block.servicesList[2].serviceTitle}
              </Heading>
              <Text fontSize="18px">{acf_services_block.servicesList[2].serviceDescription}</Text>
            </Box>
          </Box>
          <Box pos="absolute" left="700px" top="330px" w="464px">
            <Image w="464px" h="349px" objectFit="cover" src={acf_services_block.servicesList[1].mainImage.sourceUrl} />
          </Box>
        </Box>

        <Box display={{ base: 'none', lg: 'block' }} pos="relative" w="1750px" minH="1150px" mx="auto">
          <Box w="677px" pos="absolute" top="0" left="0">
            <Image w="677px" h="506px" objectFit="cover" src={acf_services_block.servicesList[3].mainImage.sourceUrl} />
            <Box w="400px" ml="auto">
              <Heading mt="2rem" pb="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
                {acf_services_block.servicesList[3].serviceTitle}
              </Heading>
              <Text fontSize="18px">{acf_services_block.servicesList[3].serviceDescription}</Text>
            </Box>
          </Box>

          <Box pos="absolute" left="700px" top="0" w="373px">
            <Heading pb="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
              {acf_services_block.servicesList[1].serviceTitle}
            </Heading>
            <Text mb="1rem" mx="auto" fontSize="18px">
              {acf_services_block.servicesList[1].serviceDescription}
            </Text>
            <Image w="464px" h="506px" objectFit="cover" src={acf_services_block.servicesList[4].mainImage.sourceUrl} />
            <Heading mt="2rem" pb="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
              {acf_services_block.servicesList[4].serviceTitle}
            </Heading>
            <Text mx="auto" fontSize="18px">
              {acf_services_block.servicesList[4].serviceDescription}
            </Text>
          </Box>
          <Box pos="absolute" right="280px" top="0" w="373px">
            <Image w="373px" h="506px" objectFit="cover" src={acf_services_block.servicesList[5].mainImage.sourceUrl} />
            <Heading mt="2rem" pb="1rem" fontSize={{ md: '20px', lg: '26px' }} color="primary">
              {acf_services_block.servicesList[5].serviceTitle}
            </Heading>
            <Text fontSize="18px">{acf_services_block.servicesList[5].serviceDescription}</Text>
          </Box>
        </Box>
      </Box>

      <ContactUsBannerWithForm
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default HandymanWork;
